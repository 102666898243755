.ant-table-thead>tr>th {
    text-align: center!important;
}
@mixin background() {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: calc(-30% + 40%);
}

@mixin display-center($display, $justify-content, $align-items) {
    display: $display;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin display-flex-direction($display, $flex-direction, $align-items) {
    display: $display;
    flex-direction: $flex-direction;
    align-items: $align-items;
}

@mixin display-grid {
    display: grid;
    grid-gap: 5px;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(470px, 1fr));
    border-radius: 10px;
}

@mixin res-ref-container {
    width: 100%;
    margin: 10px 0;
    box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
    background-color: $secondary-background;
    padding: 0 15px 15px 15px;
    border-radius: 10px;
}

@mixin dashboard-statistics-block {
    margin: 5px 5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.13) 0 0 1px 1px;
    background-color: $secondary-background;
    border-radius: 10px;
}

@mixin dashboard-statistics-block-title {
    display: flex;
    align-items: center;
    padding: 24px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 40px;
    background-color: $app-color;
}

@mixin dashboard-grafic {
    width: 90%;
    height: 200px;
    margin: 20px auto;
}

@mixin button {
    width: 150px;
    margin: 0 auto;
}

:root {
    --app-color: rgb(132, 0, 255);
    --app-color: black;
    --active-color: rgb(255, 200, 117);
    --link-color: rgb(132, 0, 255);;
    --logo-size: 70px;
    --active-background: white;
}

// $primary-color: #4cf661;
$primary-color: #e6d21b;
$color: #fff;
$secondary-background: #fff;
$danger-color: #bf4342;
$success-color: #28a745;
$font-size: 19px;
$font-style: italic;
$title-font-size: 22px;
$font-weight: 700;
$border-radius: 10px;
// $link-color: #1890ff;
$link-hover-color: #2788e4;
$opacity: 0.8;
//$primary-dark: #1A1A1A;
// $primary-dark: #073e4a;
$primary-dark: #090909;

$app-color: var(--app-color);

$active-color: var(--active-color);
$link-color: var(--link-color);
$active-background: var(--active-background);
// $logo-size: var(--logo-size);
