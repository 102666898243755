.select {
  width: 50px!important;

  img {
    width: 26px!important;
    height: 16px!important;
  }
}

// :global .ant-select {
//   width: 50px!important;
// }

.dropdown {
  width: max-content!important;

  img {
    margin-right: 10px;
    width: 26px!important;
    height: 16px!important;
  }

  .iso-code {
    font-size: 12px!important;
  }
}

.dropdown {
  overflow-y: scroll;
  height: 135px!important;
}

.dropdown::-webkit-scrollbar {
  width: 4px;
}

.dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.dropdown::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 3px;
  //outline: 1px solid slategrey;
}

@media screen and (max-width:500px) {
  
}