@import "../../../../public/assets/style/variables";

Form {
  .form-items-2 {
    Button {
      width: 100%;
    }

    :global .ant-form-item-control-input-content {
      padding: 12px 0 0 0;
    }
  }

  .form-input-block {
    :global .ant-form-item {
      height: 75px!important;
      margin-bottom: 2px;
    }
  }
}
