.subreseller-page_add-btn {
  float: right;
  margin-bottom: 15px;
}
.is_disabled td {
  background: #ffd6d6 !important;
}

.subreseller-drawer{
  .ant-drawer-content-wrapper{
    width: 600px;
  }
}

.subreseller-drawer-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  &_save-btn-container {
    display: flex;
    justify-content: center;

    &_btn {
      width: 200px;
    }
  }
}

.row{
cursor: pointer;
}

.reseller-info-drawer-list{
  list-style: none;
  border-radius: 10px;
  box-shadow: 4px 4px 12px #0000001c;
  color: #707070;
  padding: 15px 30px;
    width: 100%;

  li{
    color: #707070;
    font-size: 16px;
    font-weight: 700;
  }
}

.activation-drawer-btn-container{
    display: flex;
    justify-content: center;
}
@media screen and (max-width:600px) {
  .subreseller-drawer-content{
    grid-template-columns:1fr;
  }


}