@import "/public/assets/style/variables";

//Form {
//  .add-checkbox-block {
//    width: 25%;
//    @include display-center(flex, space-between, center);
//    a {
//      color: $link-color;
//    }
//    a:hover {
//      color: $link-hover-color;
//    }
//  }
//
//  .activate-button {
//    @include button();
//  }
//}

.drawer {
  .add-checkbox-block {
    width: 25%;
    @include display-center(flex, space-between, center);
    a {
      color: $link-color;
    }
    a:hover {
      color: $link-hover-color;
    }
  }

  .activate-button {
    @include button();
  }

  .form-input-block {
    :global .ant-form-item {
      height: 87px!important;
      margin-bottom: 2px;
    }
  }
  .packages-select{
    width: 100% !important;
  }
}
