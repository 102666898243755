@import "/public/assets/style/variables";

.message-open {
  overflow: hidden;
  height: 40px;
  transition: height .2s;
  @include display-center(flex, center, flex-start);
}

.message-close {
  transition: height 0.2s;
  overflow: hidden;
  height: 0;
  @include display-center(flex, center, flex-start);
}

.success-text {
  color: $success-color;
}

.error-text {
  color: $danger-color;
}
