@import "/public/assets/style/variables";

.referral-button {
  @include button();
}

.form-input-block {
  :global .ant-form-item {
    height: 87px!important;
    margin-bottom: 2px;
  }
}
