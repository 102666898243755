@import "/public/assets/style/variables";

Form {
  .form-items {
    text-align: center;

    Button {
      width: 100%;
      margin: 0 auto;
    }

    a {
      color: #1890ff !important;

      &:hover {
        color: #52a3ef !important;
      }
    }
  }

  .form-input-block {
    :global .ant-form-item {
      height: 62px !important;
      margin-bottom: 2px;
    }
  }
}
