@import "/public/assets/style/variables";

.activate-link,
.playlist-url {
  white-space: nowrap !important;
  max-width: 150px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.playlist-icon-container {
  svg {
    width: 23px;
    height: 23px;
  }
}


.expired-row {
  background-color: #ffd7d7;

  td{
    background-color: unset  !important;
  }
  
}

@media screen and (max-width: 500px) {
  .activate-device-button-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
}