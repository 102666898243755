@import "/public/assets/style/variables";


.mac-inp {
  width: 100%;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
}

.message-open {
  overflow: hidden;
  height: 25px;
  transition: height 0.2s;
}

.message-close {
  transition: height 0.2s;
  overflow: hidden;
  height: 0;
}

.success-text {
  color: $success-color;
}

.error-text {
  color: $danger-color;
}
