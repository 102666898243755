@import "/public/assets/style/variables";

.login-page {
  @include display-center(flex, space-between, center);
  width: 100%;
  height: 100vh;
  @include background();
  .login-page-illustration-container {
    width: 100%;
    display: none;
    img {
      width: 550px;
    }
  }
  .login-form-block {
    @include display-center(flex, center, center);
    width: 100%;
    .login-form-block-container {
    align-items: center;
    background: #00000060;
    backdrop-filter: blur(0.8rem);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-width: 450px;
    padding: 30px 0 10px;
    width: 470px;
    }
    img {
      width: 90px;
      padding: 0 0 20px 0;
    }
    .login-page-form {
      width: 80%;
    }
  }

  @media (max-width: 992px) {
    .login-page-illustration-container {
      display: none;
    }
  }

  @media (max-width: 576px) {
    .login-form-block {
      .login-form-block-container {
        width: 360px;
        min-width: 310px;
      }
    }
  }
}

@media (max-width: 992px) {
  .login-page {
    @include display-center(flex, center, center);
  }
}



