.table-action-button {
    width: 30px;
    height: 30px;
}

.table-action-button svg {
    width: 16px;
    height: 16px;
}

.separator:after {
    content: "";
    position: absolute;
    width: 43%;
    height: 1px;
    background: gray;
    transform: translate(4%, -50%);
    top: 50%;
}

.separator:before {
    content: "";
    position: absolute;
    width: 43%;
    height: 1px;
    background: gray;
    transform: translate(-105%, -50%);
    top: 50%;
}

.separator {
    width: 100%;
    text-align: center;
    position: relative;
}
