@import "/public/assets/style/variables";

.reset-block {
  .form-input-block {
    :global .ant-form-item {
      height: 87px!important;
      margin-bottom: 2px;
    }
  }
  .reset-button {
    @include button();
  }
}
