@import "/public/assets/style/variables";

.dashboard-title {
  font-weight: 700;
  font-size: 19px;
}

.reseller-statistics-cards {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
	margin-left: -15px;
    margin-right: -15px;
    justify-content: center;

  &__content {
    align-items: center;
    background: #ffffff14;
    backdrop-filter: blur(11px);
    border-radius: 1rem;
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    padding: 10px;

    &__title {
      color: #fff;
      margin-bottom: 0;
    }
  }
}
