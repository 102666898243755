@import "/public/assets/style/variables";

//Form {
//  .add-checkbox-block {
//    width: 22%;
//    @include display-center(flex, space-between, center);
//    a {
//      color: $link-color;
//    }
//    a:hover {
//      color: $link-hover-color;
//    }
//  }
//
//  .form-get-code-button {
//    @include button();
//  }
//}

.add-checkbox-block {
  width: 22%;
  @include display-center(flex, space-between, center);
  a {
    color: $link-color;
  }
  a:hover {
    color: $link-hover-color;
  }
}

.form-get-code-button {
  @include button();
}
