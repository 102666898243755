@import "/public/assets/style/variables";

.dashboard-page-card {
    background: #240a60;
    background-image: url(https://smarters.tvplayers.net/_next/static/media/background_img.b3588302.png);
    background-size: cover;
    border-radius: 10px;
    box-shadow: 4px 4px 12px rgba(0,0,0,.11);
    color: #fff;
    display: flex;
    flex: 0 0 32%;
    flex-direction: column;
    font-size: 1rem;
    height: 160px;
    justify-content: space-around;
    margin: 5px;
    max-width: 32%;
    opacity: 1;
    padding: 10px 24px;
    position: relative;
}

/* Media query for mobile devices */
@media (max-width: 967spx) {
  .dashboard-page-card {
    flex: 0 0 95% !important;
    max-width: 95% !important;
  }
}
