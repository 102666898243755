@import "/public/assets/style/variables";

.blur {
  filter: blur(20px);
  animation: blur 0.2s linear forwards;
}

@keyframes blur {
  0% {
    filter: blur(20px);
  }

  100% {
    filter: none;
  }
}

.header {
  @include display-center(flex, space-between, center);
    background: #240a60;
    background-image: url(https://restream.ibox4u.com/resellers/assets/images/pattern.png);
    background-position: top;
    background-size: inherit;
    border-radius: 1rem;
    color: #fff;
    margin-bottom: 1rem;
    padding: 10px 20px!important;

  .title {
    font-size: 22px;
    font-weight: 700;
    //margin-bottom: 2px;
  }
  .buttons {
    Button {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0;
    margin-top: 0;
    border-radius: 1rem;
    background: #ffcb54;
    color: #313131;
    border: 1.5px solid;
    line-height: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    }
  }
}

:global .ant-layout-header {
  padding: 0 20px !important;
}

.activate-link {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width:500px) {
  :global .ant-layout-header {
    padding: 0 8px !important;
  }
  
}