@import "/public/assets/style/variables";

.activate-checkbox-block {
  @include display-center(flex, "", center);
  gap: 10px;
  
  a {
    color: $link-color;
  }
  a:hover {
    color: $link-hover-color;
  }
}

.activate-button {
  @include button();
}
.packages-select{
  width:100% !important;
}