@import "/public/assets/style/variables";

.title {
  color: #fff;
  margin-bottom: 0;
  background: #ffffff1c;
  border-radius: 0.3rem;
  padding: 2px 5px;
  backdrop-filter: blur(5px);
}
 
.card-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background: #0000001c;
    border-radius: 0.3rem;
    padding: 2px 15px 1px 10px;
    backdrop-filter: blur(3px);
}

.referral-statistics-cards{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.dashboard-subtite{
  font-weight: 700;
  font-size: 19px;
}