.stripe-button {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    font-size: 22px;
    border-radius: 5px;
    font-weight: bold;
    background-color: #2c2e2e;
    font-style: italic;

    &:hover {
        opacity: 0.9;
        background-color: #2c2e2e;
    }
}
